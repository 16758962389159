// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bookshelf-index-jsx": () => import("./../../../src/pages/bookshelf/index.jsx" /* webpackChunkName: "component---src-pages-bookshelf-index-jsx" */),
  "component---src-pages-code-games-gobblet-jsx": () => import("./../../../src/pages/code/games/gobblet.jsx" /* webpackChunkName: "component---src-pages-code-games-gobblet-jsx" */),
  "component---src-pages-code-games-index-jsx": () => import("./../../../src/pages/code/games/index.jsx" /* webpackChunkName: "component---src-pages-code-games-index-jsx" */),
  "component---src-pages-code-index-jsx": () => import("./../../../src/pages/code/index.jsx" /* webpackChunkName: "component---src-pages-code-index-jsx" */),
  "component---src-pages-eeto-jsx": () => import("./../../../src/pages/eeto.jsx" /* webpackChunkName: "component---src-pages-eeto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-play-index-jsx": () => import("./../../../src/pages/play/index.jsx" /* webpackChunkName: "component---src-pages-play-index-jsx" */),
  "component---src-pages-reflections-index-jsx": () => import("./../../../src/pages/reflections/index.jsx" /* webpackChunkName: "component---src-pages-reflections-index-jsx" */),
  "component---src-pages-research-index-jsx": () => import("./../../../src/pages/research/index.jsx" /* webpackChunkName: "component---src-pages-research-index-jsx" */),
  "component---src-pages-yolkgirl-jsx": () => import("./../../../src/pages/yolkgirl.jsx" /* webpackChunkName: "component---src-pages-yolkgirl-jsx" */),
  "component---src-templates-music-jsx": () => import("./../../../src/templates/music.jsx" /* webpackChunkName: "component---src-templates-music-jsx" */),
  "component---src-templates-tag-jsx": () => import("./../../../src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-words-jsx": () => import("./../../../src/templates/words.jsx" /* webpackChunkName: "component---src-templates-words-jsx" */)
}

